<template>

  <div class="search">
    <div class="container-fluid">
      <simple-select-field class="mb20" :name="$t('search.info')" field-type-id="contentType" :values="contentTypeSelectValues" :isMultiple="false" @on-select="contentTypeChanged" :initialValue="initialValue"/>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'ContentTypeFilter',
    components: {
      SimpleSelectField: () => import('@/components/controls/SimpleSelectField.vue'),
    },
    data() {
      return {
        currentContentTypeId: null,
      }
    },
    props: {
      contentTypeSelectValues: {
        type: Array,
        default: null
      },
      initialValue: {
        type: String,
        default: ""
      }
    },
    methods: {
      contentTypeChanged(value){
        this.currentContentTypeId = value.value;
        this.$emit('contentTypeChanged',value);
      },
    }
  }
</script>
<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .search {
    width: 100vw;
    position: absolute;
    top: $title-bar-size;
    height: auto;
    padding: 15px 0;
    background: $white-color;
    box-shadow: 0px 4px 4px 0px #00000040;
  }

</style>